const apiRoot = "https://uu4ibin9u6.execute-api.eu-west-2.amazonaws.com";

const winnerPath = "/winner";
const votesPath = "/votes";
const historyPath = "/history";
const userPath = "/user";
const tempTattooPath = "/temp-tattoo";

export const VOTES_API_ROUTE = apiRoot + votesPath;
export const VOTES_HISTORY_API_ROUTE = apiRoot + votesPath + historyPath;
export const WINNER_API_ROUTE = apiRoot + winnerPath;
export const USER_API_ROUTE = apiRoot + userPath;
export const TEMP_TATTOO_API_ROUTE = apiRoot + tempTattooPath;
