import React, { useEffect, useState } from "react";
import "./App.css";
import theme from "./style/theme";
import { ThemeProvider } from "@mui/material/styles";
import HeaderBasic from "./HeaderBasic";
import HomePage from "./home/HomePage";
import AboutPage from "./about/AboutPage";
import TempTattooPage from "./temp-tattoo/TempTattooPage";
import PosterPage from "./poster/PosterPage";
import HistoryPage from "./history/HistoryPage";
import CurrentLink from "./current-link/CurrentLink";
import TempTattooRedirect from "./temp-tattoo/TempTattooRedirect";

export let PAGES = {
  home: "voting",
  about: "about",
  poster: "poster",
  tempTattoo: "temp-tattoo",
  votePreview: "supersecretpreview",
  votingHistory: "history",
};

for (let i = 0; i < 51; i++) {
  PAGES = { ...PAGES, ["temp" + i]: "temp" + i };
}

function App() {
  const [currentPage, setCurrentPage] = useState(PAGES.home);

  const navigate = (url: string) => {
    window.location.href = url;
  };

  useEffect(() => {
    const match = Object.values(PAGES).find((page) =>
      window.location.href.includes(page)
    );
    if (match) {
      setCurrentPage(match);
    }
  }, []);

  useEffect(() => {
    if (
      !Object.values(PAGES).find((page) => window.location.href.includes(page))
    ) {
      setCurrentPage("current-link");
    }
  }, []);

  if (currentPage.startsWith("temp") && currentPage !== "temp-tattoo") {
    return <TempTattooRedirect path={currentPage} />;
  }

  if (currentPage === PAGES.votePreview) {
    return (
      <div className="App" style={{ width: "100%" }}>
        <ThemeProvider theme={theme}>
          <HeaderBasic onInfoOpen={() => {}} navigate={navigate} />
          {currentPage === PAGES.votePreview && (
            <HomePage navigate={navigate} />
          )}
          {currentPage === PAGES.about && <AboutPage navigate={navigate} />}
          {currentPage === PAGES.poster && <PosterPage navigate={navigate} />}
          {currentPage === "current-link" && <CurrentLink />}
          {currentPage === "temp-tattoo" && <TempTattooPage />}
        </ThemeProvider>
      </div>
    );
  }

  if (currentPage === PAGES.votingHistory) {
    return <HistoryPage />;
  }

  return (
    <div className="App" style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        {/* <HeaderBasic onInfoOpen={() => {}} navigate={navigate} /> */}
        {currentPage !== "temp-tattoo" && <PosterPage navigate={navigate} />}
        {/* {currentPage === PAGES.home && <HomePage navigate={navigate} />}
        {currentPage === PAGES.about && <AboutPage navigate={navigate} />}
        {currentPage === PAGES.poster && <PosterPage navigate={navigate} />}
        {currentPage === "current-link" && <CurrentLink />} */}
        {currentPage === "temp-tattoo" && <TempTattooPage />}
      </ThemeProvider>
    </div>
  );
}

export default App;
