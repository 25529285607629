import React from "react";
import "@fontsource-variable/fira-code";
import { Box, Container } from "@mui/material";
import PageLinkBasic from "../common/PageLinkBasic";
import posterImage from "../img/inkedlinkposter.png";
import { PAGES } from "../App";

const styles = {
  p: {
    marginBottom: 2,
    textAlign: "left",
  },
  creditTitle: {
    textAlign: "left",
    textDecoration: "underline",
  },
};

function PosterPage({ navigate }: { navigate: (x: string) => void }) {
  return (
    <div>
      {/* <PageLinkBasic
        text={"Back"}
        onClick={() => navigate(PAGES.home)}
        backwards
      /> */}
      <Container sx={{}}>
        <Box
          component="img"
          sx={{
            width: "40vh",
            maxWidth: "100vw",
            height: "90vh",
            alignContent: "center",
          }}
          src={posterImage}
        />
      </Container>
    </div>
  );
}

export default PosterPage;
