import * as React from "react";
import { useEffect, useRef, useState } from "react";
import getAllVotes from "../api/votes/getAllVotes";
import { CSVLink } from "react-csv";

function HistoryPage() {
  const [transactionData, setTransactionData] = useState<any[]>([]);

  useEffect(() => {
    getAllVotes().then((votes) => {
      votes = votes.sort((a, b) => a.date.localeCompare(b.date));
      const csvData = jsonToCsvData(votes);
      console.log(csvData);
      setTransactionData(csvData);
    });
  }, []);

  return (
    <CSVLink
      data={transactionData}
      filename="votes.csv"
      className="hidden"
      target="_blank"
    >
      Download
    </CSVLink>
  );
}

function jsonToCsvData(jsonData: any[]): string[][] {
  let csv: string[][] = [];

  // Extract headers
  let headers = Object.keys(jsonData[0]);
  headers = headers.filter((h) => h !== "id");
  csv.push(headers);

  // Extract values
  jsonData.forEach((obj) => {
    let values = headers.map((header) =>
      obj[header] ? obj[header].toString() : ""
    );
    values = values.filter((v) => v.id !== "current-winner-id");
    for (const value of values) {
      delete value.id;
    }
    csv.push(values);
  });

  return csv;
}

export default HistoryPage;
