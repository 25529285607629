import React, { useEffect, useState } from "react";
import "@fontsource-variable/fira-code";
import {
  Box,
  Button,
  Container,
  Divider,
  List,
  TextField,
} from "@mui/material";
import getTempTattoos from "../api/temp-tattoo/getTempTattoos";
import ITempTattoo from "../api/models/ITempTattoo";
import updateTempTattoo from "../api/temp-tattoo/updateTempTattoo";
import addVote from "../api/votes/addVote";

const styles = {
  p: {
    marginBottom: 2,
    textAlign: "left",
  },
  creditTitle: {
    textAlign: "left",
    textDecoration: "underline",
  },
};

function TempTattooListItem({ tattoo }: { tattoo: ITempTattoo }) {
  const [newLink, setNewLink] = useState(tattoo.redirectLink);
  const [updated, setUpdated] = useState(true);

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      width="100%"
      sx={{
        height: 80,
      }}
    >
      <div>{tattoo.link}</div>
      <TextField
        margin="dense"
        id="link"
        name="redirect link"
        type="url"
        fullWidth
        variant="standard"
        value={newLink}
        onChange={(x) => {
          setNewLink(x.target.value);
          setUpdated(false);
        }}
      />
      <Button
        variant="contained"
        disabled={updated}
        onClick={async () => {
          await updateTempTattoo({
            ...tattoo,
            redirectLink: newLink,
          });
          await addVote(newLink);
          setUpdated(true);
        }}
      >
        Save
      </Button>
    </Box>
  );
}

export default TempTattooListItem;
