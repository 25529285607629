import { WINNER_API_ROUTE } from "../apiRoutes";
import IVoteItem from "../models/IVoteItem";

async function getCurrentWinner(): Promise<IVoteItem | null> {
  const res = await fetch(WINNER_API_ROUTE, {
    method: "GET",
  });

  if (!res.ok) {
    return null;
  }

  const model: IVoteItem = await res.json();

  return model;
}

export default getCurrentWinner;
