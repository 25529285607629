import React, { useEffect, useState } from "react";
import "@fontsource-variable/fira-code";
import {
  Box,
  Button,
  Container,
  Divider,
  List,
  TextField,
} from "@mui/material";
import getTempTattoos from "../api/temp-tattoo/getTempTattoos";
import ITempTattoo from "../api/models/ITempTattoo";
import updateTempTattoo from "../api/temp-tattoo/updateTempTattoo";
import TempTattooListItem from "./TempTattooListItem";

const styles = {
  p: {
    marginBottom: 2,
    textAlign: "left",
  },
  creditTitle: {
    textAlign: "left",
    textDecoration: "underline",
  },
};

function TempTattooPage() {
  const [tattoos, setTattoos] = useState<ITempTattoo[]>([]);
  const [password, setPassword] = useState("");

  useEffect(() => {
    getTempTattoos().then((res) => {
      if (res) {
        setTattoos(res);
      }
    });
  }, []);

  if (password !== "tomlikestomatoes") {
    return (
      <Box>
        <TextField
          margin="dense"
          id="link"
          name="password"
          label="Password"
          fullWidth
          variant="standard"
          value={password}
          onChange={(x) => {
            setPassword(x.target.value);
          }}
        />
      </Box>
    );
  }

  return (
    <div>
      <Container sx={{}}>
        <div>Links should start with https:// </div>
        <Box display="flex" justifyContent="center" alignItems="center">
          <List
            sx={{
              width: "100%",
            }}
          >
            <Divider />
            {tattoos.map((tattoo) => (
              <TempTattooListItem tattoo={tattoo} key={tattoo.link} />
            ))}
          </List>
        </Box>
      </Container>
    </div>
  );
}

export default TempTattooPage;
