import { VOTES_HISTORY_API_ROUTE } from "../apiRoutes";
import IVoteItem from "../models/IVoteItem";

type IVoteItemWithDate = IVoteItem & { date: string };

async function getAllVotes(): Promise<IVoteItemWithDate[]> {
  const res = await fetch(VOTES_HISTORY_API_ROUTE, {
    method: "GET",
  });

  if (!res.ok) {
    return [];
  }

  const model: IVoteItemWithDate[] = await res.json();

  return model;
}

export default getAllVotes;
