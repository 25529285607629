import React, { useEffect, useState } from "react";
import "@fontsource-variable/fira-code";
import {
  Box,
  Button,
  Container,
  Divider,
  List,
  TextField,
} from "@mui/material";
import getTempTattoos from "../api/temp-tattoo/getTempTattoos";
import ITempTattoo from "../api/models/ITempTattoo";
import updateTempTattoo from "../api/temp-tattoo/updateTempTattoo";
import TempTattooListItem from "./TempTattooListItem";

const styles = {
  p: {
    marginBottom: 2,
    textAlign: "left",
  },
  creditTitle: {
    textAlign: "left",
    textDecoration: "underline",
  },
};

function TempTattooRedirect({ path }: { path: string }) {
  const [tattoos, setTattoos] = useState<ITempTattoo[]>([]);

  useEffect(() => {
    getTempTattoos().then((res) => {
      if (res) {
        setTattoos(res);
      }
    });
  }, []);

  const match = tattoos.find((t) => t.link === path);

  if (match && match.redirectLink) {
    window.location.replace(match.redirectLink);
  }

  return <></>;
}

export default TempTattooRedirect;
