import { TEMP_TATTOO_API_ROUTE } from "../apiRoutes";
import ITempTattoo from "../models/ITempTattoo";

async function getTempTattoos(): Promise<ITempTattoo[]> {
  const res = await fetch(TEMP_TATTOO_API_ROUTE, {
    method: "GET",
  });

  if (!res.ok) {
    return [];
  }

  let model: ITempTattoo[] = await res.json();
  model = model.sort((a, b) => {
    const aTrimmed = a.link.split("temp")[1];
    const bTrimmed = b.link.split("temp")[1];
    return parseInt(aTrimmed) - parseInt(bTrimmed);
  });

  return model;
}

export default getTempTattoos;
