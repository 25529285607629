import { TEMP_TATTOO_API_ROUTE } from "../apiRoutes";
import ITempTattoo from "../models/ITempTattoo";

async function updateTempTattoo(
  tattoo: ITempTattoo
): Promise<ITempTattoo | null> {
  const res = await fetch(TEMP_TATTOO_API_ROUTE, {
    method: "POST",
    body: JSON.stringify(tattoo),
  });

  if (!res.ok) {
    console.log("update failed");
    return null;
  }

  const model: ITempTattoo = await res.json();

  return model;
}

export default updateTempTattoo;
