import React, { useState } from "react";
import "@fontsource-variable/fira-code";
import { AppBar, Box, Container, Snackbar, Toolbar } from "@mui/material";
import PageLinkBasic from "../common/PageLinkBasic";
import colours from "../style/colours";
import { PAGES } from "../App";

const styles = {
  p: {
    marginBottom: 2,
    textAlign: "left",
  },
  creditTitle: {
    textAlign: "left",
    textDecoration: "underline",
  },
};
function AboutPage({ navigate }: { navigate: (x: string) => void }) {
  return (
    <div>
      <PageLinkBasic
        text={"Back"}
        onClick={() => navigate(PAGES.home)}
        backwards
      />
      <Container sx={{ marginTop: 5 }}>
        <Box sx={styles.p}>
          The Inked Link is participatory performance art, happening
          simultaneously online and IRL.
        </Box>
        <Box sx={styles.p}>
          {" "}
          A QR code is tattooed on the artist's body, and is controlled entirely
          by a website www.theinkedlink.com/vote
        </Box>
        <Box sx={styles.p}>
          The public can vote for a link or submit a new link - to any website,
          video or image on the internet.
        </Box>
        <Box sx={styles.p}>
          The QR code changes to the top-voted link on the website everyday at
          00:00am.
        </Box>
        <Box sx={styles.p}>
          This performance will last 1 year. Documentation and daily updates can
          be found on{" "}
          <a
            style={{ color: colours.primary }}
            href="https://www.instagram.com/the.inked.link"
          >
            Instagram
          </a>{" "}
          and{" "}
          <a
            style={{ color: colours.primary }}
            href="https://www.tiktok.com/@the.inked.link"
          >
            TikTok
          </a>{" "}
          @the.inked.link
        </Box>

        <Box sx={styles.p}>
          This project is created to explore the responsibility of the public
          given anonymous control over another human body. The tattoo becomes a
          platform itself, a physical space holding the potential for messages
          whether political, comedic or crude.
        </Box>

        <Box sx={styles.p}>
          Reflecting the internet as an overload of information, social identity
          and online community. Through participation and collective
          responsibility, one anticipates the worst while simultaneously hoping
          for the protection of strangers.
        </Box>

        <Box sx={{ ...styles.p, marginTop: 5, fontWeight: "bold" }}>
          Created by:
        </Box>

        <Box sx={styles.creditTitle}>Artist</Box>
        <Box sx={styles.p}>Briony Godivala</Box>

        <Box sx={styles.creditTitle}>Facilitator, Coordinator</Box>
        <Box sx={styles.p}>Tom Gibson</Box>

        <Box sx={styles.creditTitle}>Software Engineer</Box>
        <Box sx={styles.p}>Andrew Hollis</Box>
      </Container>
    </div>
  );
}

export default AboutPage;
